import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  // Define your styles here

  second_section_Box_Title: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,

    "@media (min-width:0px)": { marginTop: "10%" },
    "@media (min-width:600px)": { marginTop: "10%" },
    "@media (min-width:900px)": { marginTop: "0%" },
    "@media (min-width:1200px)": { marginTop: "0%" },
    "@media (min-width:1536px)": { marginTop: "0%" },
  },
  second_section_Box_PC: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 10,
    "@media (min-width:0px)": {
      display: "none",
    },
    "@media (min-width:600px)": {
      display: "none",
    },
    "@media (min-width:900px)": {
      display: "none",
    },
    "@media (min-width:1200px)": {
      display: "flex",
      justifyContent: "space-between",
    },
    "@media (min-width:1536px)": {
      width: "70%",
    },
  },
  second_section_Box_Mobile: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 10,
    "@media (min-width:0px)": {
      display: "contents",
    },
    "@media (min-width:600px)": {
      display: "contents",
    },
    "@media (min-width:900px)": {
      display: "contents",
    },
    "@media (min-width:1200px)": {
      display: "none",
    },
    "@media (min-width:1536px)": {
      display: "none",
    },
  },

  second_section_title: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "100%",
    },
    "@media (min-width:600px)": {
      width: "100%",
    },
    "@media (min-width:900px)": {
      width: "80%",
    },
    "@media (min-width:1200px)": {
      width: "70%",
    },
    "@media (min-width:1536px)": {
      width: "70%",
    },
  },

  second_section_right: {
    width: "100%",
    height: "70%",
    "@media (min-width:0px)": {
      width: "50%",
      marginLeft: "55%",
    },
    "@media (min-width:600px)": {
      width: "50%",
      marginLeft: "50%",
    },
    "@media (min-width:900px)": {
      width: "40%",
      marginLeft: "60%",
    },
    "@media (min-width:1200px)": {
      width: "400px",
      height: "220px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "2%",
    },
    "@media (min-width:1536px)": {
      width: "550px",
      height: "250px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "100%",
    },
  },
  second_section_elevate: {
    marginTop: "-15%",
    paddingLeft: "10%",
    width: "100%",
    "@media (min-width:0px)": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "-15%",
      marginLeft: "-5%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "-15%",
      marginLeft: "-5%",
    },
    "@media (min-width:900px)": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "-15%",
      marginLeft: "-5%",
    },
    "@media (min-width:1200px)": {
      width: "110%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: "10%",
      marginTop: "-15%",
    },
    "@media (min-width:1536px)": {
      width: "170%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: "10%",
      marginTop: "-25%",
    },
  },
  second_section_bean: {
    marginTop: "-15%",
    marginLeft: "40%",
    "@media (min-width:0px)": {
      marginTop: "-25%",
      marginLeft: "35%",
    },
    "@media (min-width:600px)": {
      marginTop: "-25%",
      marginLeft: "35%",
    },
    "@media (min-width:900px)": {
      marginTop: "-30%",
      marginLeft: "40%",
    },
    "@media (min-width:1200px)": {
      marginTop: "-22%",
      marginLeft: "35%",
    },
    "@media (min-width:1536px)": {
      marginTop: "-20%",
      marginLeft: "30%",
    },
  },
  second_section_bean_img: {
    "@media (min-width:0px)": {
      width: "55%",
      display: "flex",
    },
    "@media (min-width:600px)": {
      width: "55%",
      display: "flex",
    },
    "@media (min-width:900px)": {
      width: "55%",
      display: "flex",
    },
    "@media (min-width:1200px)": {
      width: "30%",
      display: "flex",
    },
    "@media (min-width:1536px)": {
      width: "30%",
      display: "flex",
    },
  },

  /*** Outline_Second_Section ***/
  ///////////////////////////////
  second_section_outline: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "100%",
      height: "150px",
      display: "flex",
    },
    "@media (min-width:600px)": {
      width: "100%",
      height: "250px",
      display: "flex",
    },
    "@media (min-width:900px)": {
      width: "100%",
      height: "350px",
      display: "flex",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      height: "350px",
      display: "flex",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      height: "450px",
      display: "flex",
    },
  },
});
