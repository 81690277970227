import { Box, Container } from "@mui/material";
import React from "react";
import title from "../../../../assets/Home/6th/Slider/Slide_3/meticulous_roasting.png";
import curve from "../../../../assets/Home/6th/Slider/Slide_3/curve.png";
import beans_hour from "../../../../assets/Home/6th/Slider/Slide_3/beans_hour.png";
import trasn_text from "../../../../assets/Home/6th/Slider/Slide_3/transformation_text.png";
import text from "../../../../assets/Home/6th/Slider/Slide_3/slide_3_text.png";
import beansImg from "../../../../assets/Home/6th/Slider/Slide_3/been_image_illistration.png";
import exceptional from "../../../../assets/Home/6th/Slider/Slide_3/exceptional_coffee_drinking_experience.png";

const Slide4Th = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Box py={"2%"}>
          <Box>
            <Box
              component={"img"}
              src={title}
              alt=""
              sx={{
                width: {
                  xl: "55%",
                  lg: "55%",
                  md: "90%",
                  sm: "90%",
                  xs: "90%",
                },
              }}
            />
          </Box>
          <Box>
            <Box
              component={"img"}
              src={curve}
              alt=""
              sx={{
                width: {
                  xl: "35%",
                  lg: "35%",
                  md: "35%",
                  sm: "35%",
                  xs: "25%",
                },
              }}
            />
          </Box>
          <Box
            display={"flex"}
            sx={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
            }}
            justifyContent={"space-around"}
          >
            <Box>
              <Box
                component={"img"}
                src={beans_hour}
                alt=""
                sx={{
                  width: {
                    xl: "80%",
                    lg: "80%",
                    md: "50%",
                    sm: "35%",
                    xs: "35%",
                  },
                  mr: -0,
                  mt: { xl: -10, lg: -8 },
                }}
              />
            </Box>
            <Box>
              <Box
                component={"img"}
                src={trasn_text}
                alt=""
                sx={{
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-around"}
            sx={{ paddingTop: { xl: "3%" } }}
          >
            <Box>
              <Box
                component={"img"}
                src={text}
                alt=""
                sx={{
                  width: {
                    xl: "110%",
                    lg: "100%",
                    md: "",
                    sm: "",
                    xs: "",
                  },
                  mt: { xl: -5 },
                  ml: { xl: -10 },
                }}
              />
            </Box>
            <Box>
              <Box
                component={"img"}
                src={beansImg}
                alt=""
                sx={{
                  width: {
                    xl: "75%",
                    lg: "75%",
                    md: "",
                    sm: "",
                    xs: "",
                  },
                  mt: { xl: -8 },
                  ml: { xl: 20 },
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box
              component={"img"}
              src={exceptional}
              alt=""
              sx={{
                width: {
                  xl: "50%",
                  lg: "50%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Slide4Th;
