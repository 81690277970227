import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  //////////////--Start Of Style1st--//////////////
  main: {
    "@media (min-width:0px)": { padding: "0px" },
    "@media (min-width:600px)": { padding: "0px" },
    "@media (min-width:900px)": { padding: "0px" },
    "@media (min-width:1200px)": { padding: "0px" },
    "@media (min-width:1440px)": { padding: "0%" },
    "@media (min-width:1536px)": {
      padding: "0",
    },
  },
  Craft_Box: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media (min-width:0px)": { paddingLeft: "20px" },
    "@media (min-width:600px)": { paddingLeft: "50px" },
    "@media (min-width:900px)": { paddingLeft: "50px" },
    "@media (min-width:1200px)": { paddingLeft: "50px" },
    "@media (min-width:1536px)": {
      paddingLeft: "70px",
    },
  },
  Craft: {
    width: "100%",
    "@media (min-width:0px)": { width: "90%" },
    "@media (min-width:600px)": { width: "90%" },
    "@media (min-width:900px)": { width: "60%" },
    "@media (min-width:1200px)": {
      width: "50%",
    },
    "@media (max-width:1440px)": {
      width: "40%",
      paddingTop: "20%",
    },

    "@media (min-width:1536px)": { width: "50%" },
  },
  blends_Box: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "@media (min-width:0px)": { paddingTop: "5%", paddingRight: "0%" },
    "@media (min-width:600px)": { paddingTop: "5%", paddingRight: "0%" },
    "@media (min-width:900px)": { paddingTop: "5%", paddingRight: "20%" },
    "@media (min-width:1200px)": { paddingTop: "5%", paddingRight: "20%" },
    "@media (min-width:1536px)": {
      paddingTop: "2%",
      paddingRight: "15%",
    },
  },
  blends: {
    width: "100%",
    "@media (min-width:0px)": { width: "90%" },
    "@media (min-width:600px)": { width: "85%" },
    "@media (min-width:900px)": { width: "60%" },
    "@media (min-width:1200px)": {
      width: "60%",
    },
    "@media (min-width:1536px)": { width: "60%" },
  },
  taste_Box: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "@media (min-width:0px)": {
      marginTop: "-15%",
      paddingRight: "10%",
      marginBottom: "10%",
    },
    "@media (min-width:600px)": {
      marginTop: "-14%",
      paddingRight: "10%",
      marginBottom: "10%",
    },
    "@media (min-width:900px)": {
      marginTop: "-7%",
      paddingLeft: "22%",
      marginBottom: "10%",
    },
    "@media (min-width:1200px)": {
      marginTop: "-7%",
      paddingLeft: "15%",
      marginBottom: "10%",
    },
    "@media (max-width:1440px)": {
      marginTop: "-8%",
      paddingLeft: "25%",
      marginBottom: "10%",
    },
    "@media (min-width:1536px)": {
      marginTop: "-7%",
      paddingLeft: "25%",
      marginBottom: "10%",
    },
  },
  taste: {
    width: "100%",
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "120%" },
    "@media (min-width:900px)": { width: "100%" },
    "@media (min-width:1200px)": {
      width: "100%",
    },
    "@media (min-width:1536px)": { width: "140%" },
  },
  curve_Box: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "@media (min-width:0px)": { paddingTop: "5%", paddingLeft: "20px" },
    "@media (min-width:600px)": {
      marginTop: "-7%",
      paddingLeft: "30px",
      marginRight: "-30px",
    },
    "@media (min-width:900px)": { paddingTop: "5%", paddingRight: "75px" },
    "@media (min-width:1200px)": { paddingTop: "5%", paddingRight: "75px" },
    "@media (min-width:1536px)": {
      paddingTop: "5%",
      paddingRight: "75px",
    },
  },
  curve: {
    width: "100%",
    "@media (min-width:0px)": { width: "80%" },
    "@media (min-width:600px)": { width: "80%" },
    "@media (min-width:900px)": { width: "55%" },
    "@media (min-width:1200px)": {
      width: "55%",
    },
    "@media (min-width:1536px)": { width: "55%" },
  },

  //////////////--End Of Style1st--//////////////
  ///////////////////////////////////////////////
  //////////////--Start Of Style2Nd--////////////

  Source: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "100%",
      marginLeft: "10%",
      marginTop: "5%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      marginLeft: "10%",
      marginTop: "20%",
    },
    "@media (min-width:900px)": {
      width: "115%",
      marginLeft: "10%",
      marginTop: "20%",
    },
    "@media (min-width:1200px)": {
      width: "115%",
      marginLeft: "0%",
      marginTop: "20%",
    },
    "@media (min-width:1440px)": {
      width: "100%",
      paddingLeft: "10%",
      marginTop: "5%",
    },
    "@media (min-width:1536px)": {
      width: "115%",
      marginLeft: "20%",
      marginTop: "20%",
    },
  },

  everyCup: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "100%",
      marginTop: "5%",
      marginLeft: "-25%",
    },
    "@media (min-width:600px)": {
      width: "90%",
      marginTop: "12%",
      marginLeft: "-15%",
    },
    "@media (min-width:900px)": {
      width: "90%",
      marginTop: "12%",
      marginLeft: "-15%",
    },
    "@media (min-width:1200px)": {
      width: "90%",
      marginTop: "12%",
      marginLeft: "-15%",
    },
    "@media (max-width:1440px)": {
      width: "70%",
      marginTop: "0%",
      marginLeft: "-30%",
    },
    "@media (min-width:1536px)": {
      width: "90%",
      marginTop: "12%",
      marginLeft: "-15%",
    },
  },

  bean_box: {
    "@media (min-width:0px)": {
      marginLeft: "-35%",
      paddingRight: "0%",
      marginTop: "15%",
    },
    "@media (min-width:600px)": {
      marginLeft: "-30%",
      paddingRight: "0%",
      marginTop: "10%",
    },
    "@media (min-width:900px)": {
      marginLeft: "-30%",
      paddingRight: "5%",
      marginTop: "15%",
    },
    "@media (min-width:1200px)": {
      marginLeft: "-30%",
      paddingRight: "10%",
      marginTop: "15%",
    },
    "@media (max-width:1440px)": {
      paddingLeft: "-40%",
      paddingRight: "10%",
      marginTop: "5%",
    },
    "@media (min-width:1536px)": {
      marginLeft: "-20%",
      marginTop: "15%",
      paddingRight: "15%",
    },
  },

  bean: {
    width: "100%",
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "70%" },
    "@media (min-width:900px)": { width: "100%" },
    "@media (min-width:1200px)": { width: "100%" },
    "@media (max-width:1440px)": { width: "90%" },
    "@media (min-width:1536px)": { width: "100%" },
  },

  nicaragua_map_box: {
    "@media (min-width:0px)": { marginLeft: "-15%", marginTop: "-40%" },
    "@media (min-width:600px)": { marginLeft: "0%", marginTop: "-18%" },
    "@media (min-width:900px)": { marginLeft: "10%", marginTop: "-15%" },
    "@media (min-width:1200px)": { marginLeft: "10%", marginTop: "-15%" },
    "@media (max-width:1440px)": { marginLeft: "10%", marginTop: "-15%" },
    "@media (min-width:1536px)": { marginLeft: "10%", marginTop: "-10%" },
  },

  nicaragua_map: {
    width: "100%",
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "90%" },
    "@media (min-width:900px)": { width: "100%" },
    "@media (min-width:1200px)": { width: "100%" },
    "@media (max-width:1440px)": { width: "70%" },
    "@media (min-width:1536px)": { width: "100%" },
  },
  nicaragua_text_box: {
    "@media (min-width:0px)": { marginLeft: "0%", marginTop: "30%" },
    "@media (min-width:600px)": { marginLeft: "0%", marginTop: "5%" },
    "@media (min-width:900px)": { marginLeft: "5%", marginTop: "5%" },
    "@media (min-width:1200px)": { marginLeft: "5%", marginTop: "5%" },
    "@media (max-width:1440px)": { paddingRight: "5%", marginTop: "-5%" },
    "@media (min-width:1536px)": { marginLeft: "5%", marginTop: "5%" },
  },

  nicaragua_text: {
    width: "100%",
    "@media (min-width:0px)": { width: "220%", marginLeft: "-100%" },
    "@media (min-width:600px)": { width: "100%", marginLeft: "0" },
    "@media (min-width:900px)": { width: "100%", marginLeft: "0" },
    "@media (min-width:1200px)": { width: "100%", marginLeft: "0" },
    "@media (max-width:1440px)": { width: "80%", marginLeft: "0" },
    "@media (min-width:1536px)": { width: "100%", marginLeft: "0%" },
  },

  //////////////--End Of Style2Nd--//////////////
  ///////////////////////////////////////////////
  //////////////--Start Of Style3Rd--////////////
  artistic_box: {
    width: "100",
    "@media (min-width:0px)": { marginBottom: "5%" },
    "@media (min-width:600px)": { marginBottom: "5%" },
    "@media (min-width:900px)": { marginBottom: "5%" },
    "@media (min-width:1200px)": { marginBottom: "5%" },
    "@media (max-width:1440px)": { marginBottom: "0%" },
    "@media (min-width:1536px)": { marginBottom: "0%" },
  },
  artistic_image: {
    width: "100%",
    "@media (min-width:0px)": { width: "90%" },
    "@media (min-width:600px)": { width: "90%" },
    "@media (min-width:900px)": { width: "100%" },
    "@media (min-width:1200px)": { width: "100%" },
    "@media (max-width:1440px)": { width: "90%" },
    "@media (min-width:1536px)": { width: "100%" },
  },
  curve_box: {
    "@media (min-width:0px)": { display: "none" },
    "@media (min-width:600px)": { width: "40%", display: "none" },
    "@media (min-width:900px)": { display: "none" },
    "@media (min-width:1200px)": { width: "40%", display: "inline-block" },
    "@media (min-width:1536px)": { width: "40%", display: "inline-block" },
  },
  curve_image: {
    "@media (min-width:0px)": {},
    "@media (min-width:600px)": {},
    "@media (min-width:900px)": {},
    "@media (min-width:1200px)": {},
    "@media (min-width:1536px)": {},
  },
  text_box: {
    "@media (min-width:0px)": { width: "", marginTop: "" },
    "@media (min-width:600px)": { width: "", marginTop: "" },
    "@media (min-width:900px)": { width: "", marginTop: "" },
    "@media (min-width:1200px)": { width: "", marginTop: "" },
    "@media (max-width:1440px)": {
      width: "20%",
      marginTop: "-5%",
    },
    "@media (min-width:1536px)": { width: "", marginTop: "" },
  },
  text_image: {
    width: "100%",
    "@media (min-width:0px)": { width: "80%", marginBottom: "-40%" },
    "@media (min-width:600px)": { width: "50%", marginBottom: "-30%" },
    "@media (min-width:900px)": { width: "50%", marginBottom: "-30%" },
    "@media (min-width:1200px)": { width: "50%", marginBottom: "-30%" },
    "@media (max-width:1440px)": {
      width: "90%",
      marginBottom: "-20%",
      paddingLeft: "20%",
    },
    "@media (min-width:1536px)": { width: "50%", marginBottom: "-30%" },
  },
  mountain_image: {
    width: "100%",
    "@media (min-width:0px)": { width: "75%", marginTop: "20%" },
    "@media (min-width:600px)": { width: "75%", marginTop: "20%" },
    "@media (min-width:900px)": { width: "75%", marginTop: "20%" },
    "@media (min-width:1200px)": { width: "75%", marginTop: "20%" },
    "@media (max-width:1440px)": { width: "130%", marginTop: "0%" },
    "@media (min-width:1536px)": { width: "75%", marginTop: "20%" },
  },
  nicaragua_mount_image: {
    "@media (min-width:0px)": {
      width: "40%",
      height: "40%",
      marginTop: "20%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      height: "40%",
      marginTop: "10%",
    },
    "@media (min-width:900px)": {
      width: "50%",
      height: "40%",
      marginTop: "10%",
    },
    "@media (min-width:1200px)": {
      width: "50%",
      height: "40%",
      marginTop: "10%",
    },
    "@media (max-width:1440px)": {
      width: "30%",
      height: "30%",
      marginTop: "0%",
    },
    "@media (min-width:1536px)": {
      width: "50%",
      height: "40%",
      marginTop: "10%",
    },
  },
  mountain_gold_image: {
    "@media (min-width:0px)": { width: "100%", marginTop: "-15%" },
    "@media (min-width:600px)": { width: "100%", marginTop: "-15%" },
    "@media (min-width:900px)": { width: "100%", marginTop: "-15%" },
    "@media (min-width:1200px)": { width: "100%", marginTop: "-15%" },
    "@media (max-width:1440px)": {
      width: "100%",
      height: "250px",
      marginTop: "-10%",
    },
    "@media (min-width:1536px)": { width: "100%", marginTop: "-15%" },
  },
  //////////////--End Of Style3rd--//////////////
  ///////////////////////////////////////////////
  //////////////--Start Of Style4th--////////////
  title_box: {
    "@media (min-width:0px)": {
      paddingRight: "12%",
      width: "100%",
      marginTop: "10%",
      justifyContent: "center",
      display: "flex",
    },
    "@media (min-width:600px)": {
      paddingRight: "12%",
      width: "100%",
      marginTop: "10%",
      justifyContent: "center",
      display: "flex",
    },
    "@media (min-width:900px)": {
      paddingRight: "10%",
      width: "100%",
      marginTop: "10%",
      justifyContent: "center",
      display: "flex",
    },
    "@media (min-width:1200px)": {
      paddingRight: "12%",
      width: "100%",
      marginTop: "10%",
      justifyContent: "center",
      display: "flex",
    },
    "@media (max-width:1440px)": {
      paddingRight: "12%",
      width: "100%",
      marginTop: "-9%",
      justifyContent: "center",
      display: "flex",
    },
    "@media (min-width:1536px)": {
      paddingRight: "12%",
      width: "100%",
      marginTop: "10%",
      justifyContent: "center",
      display: "flex",
    },
  },
  title: {
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "70%" },
    "@media (min-width:900px)": { width: "60%" },
    "@media (min-width:1200px)": { width: "50%" },
    "@media (max-width:1440px)": { width: "40%" },
    "@media (min-width:1536px)": { width: "50%" },
  },
  curveImage: {
    "@media (min-width:0px)": { width: "50%" },
    "@media (min-width:600px)": { width: "30%" },
    "@media (min-width:900px)": { width: "30%" },
    "@media (min-width:1200px)": { width: "25%" },
    "@media (max-width:1440px)": { width: "20%" },

    "@media (min-width:1536px)": { width: "25%" },
  },
  beans_hour_Image: {
    "@media (min-width:0px)": { width: "100%", paddingTop: "80%" },
    "@media (min-width:600px)": { width: "90%", paddingTop: "0%" },
    "@media (min-width:900px)": { width: "90%", paddingTop: "0%" },
    "@media (min-width:1200px)": { width: "100%", paddingTop: "0%" },
    "@media (max-width:1440px)": { width: "70%", paddingTop: "0%" },
    "@media (min-width:1536px)": { width: "110%", paddingTop: "0%" },
  },
  trans_text_box: {
    "@media (min-width:0px)": {
      marginRight: "10%",
    },
    "@media (min-width:600px)": {
      paddingRight: "10%",
    },
    "@media (min-width:900px)": {
      paddingRight: "10%",
    },
    "@media (min-width:1200px)": {
      paddingRight: "5%",
    },
    "@media (max-width:1440px)": {
      paddingRight: "5%",
    },
    "@media (min-width:1536px)": {
      paddingRight: "5%",
    },
  },
  trans_text_Image: {
    "@media (min-width:0px)": {
      width: "160%",
      paddingTop: "10%",
      marginLeft: "-50%",
    },
    "@media (min-width:600px)": {
      width: "115%",
      paddingTop: "4%",
      marginLeft: "0%",
    },
    "@media (min-width:900px)": {
      width: "100%",
      paddingTop: "5%",
      marginLeft: "0%",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      paddingTop: "5%",
      marginLeft: "0%",
    },
    "@media (max-width:1440px)": {
      width: "90%",
      paddingTop: "5%",
      marginLeft: "25%",
    },
    "@media (min-width:1536px)": {
      width: "120%",
      paddingTop: "5%",
      marginLeft: "0%",
    },
  },
  text_img_box: {
    "@media (min-width:0px)": {
      marginTop: "-30%",
      marginLeft: "15%",
    },
    "@media (min-width:600px)": {
      marginTop: "-10%",
      marginLeft: "15%",
    },
    "@media (min-width:900px)": {
      marginTop: "-10%",
      marginLeft: "15%",
    },
    "@media (min-width:1200px)": {
      marginTop: "-10%",
      marginLeft: "15%",
    },
    "@media (max-width:1440px)": {
      marginTop: "-10%",
      marginLeft: "15%",
    },
    "@media (min-width:1536px)": {
      marginTop: "-10%",
      marginLeft: "15%",
    },
  },
  textImg: {
    width: "100%",
    "@media (min-width:0px)": {
      display: "none",
    },
    "@media (min-width:600px)": {
      display: "none",
    },
    "@media (min-width:900px)": {
      display: "none",
    },
    "@media (min-width:1200px)": {
      display: "flex",
      marginLeft: "-30%",
      width: "120%",
    },
    "@media (max-width:1440px)": {
      display: "flex",
      marginLeft: "20%",
      width: "95%",
      marginTop: "-11%",
    },
    "@media (min-width:1536px)": {
      display: "flex",
      marginLeft: "-30%",
      width: "120%",
    },
  },
  beansImg_box: {
    "@media (min-width:0px)": {
      marginTop: "-30%",
      marginLeft: "30%",
    },
    "@media (min-width:600px)": {
      marginTop: "-10%",
      marginLeft: "25%",
    },
    "@media (min-width:900px)": {
      marginTop: "-10%",
      marginLeft: "15%",
    },
    "@media (min-width:1200px)": {
      marginTop: "-10%",
      marginLeft: "15%",
    },
    "@media (max-width:1440px)": {
      marginTop: "-8%",
      marginLeft: "15%",
    },
    "@media (min-width:1536px)": {
      marginTop: "-10%",
      marginLeft: "15%",
    },
  },

  beansImg: {
    "@media (min-width:0px)": { width: "130%" },
    "@media (min-width:600px)": { width: "90%" },
    "@media (min-width:900px)": { width: "120%" },
    "@media (min-width:1200px)": { width: "130%" },
    "@media (max-width:1440px)": { width: "90%" },
    "@media (min-width:1536px)": { width: "100%" },
  },
  exceptional: {
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "80%" },
    "@media (min-width:900px)": { width: "70%" },
    "@media (min-width:1200px)": { width: "50%" },
    "@media (min-width:1536px)": { width: "50%" },
  },
});
