import { Box, Typography } from "@mui/material";
import React from "react";
import Social from "../Header/Social";
import logo from "../../assets/logo-coffee.png";

const Footer = () => {
  return (
    <>
      <Box gap={2}>
        <Box>
          <Social />
        </Box>
        <Box>
          <Typography variant="h5">2024 &copy; All rights reserved</Typography>
        </Box>
        <Box
          component={"img"}
          src={logo}
          sx={{
            width: { xl: "5%", lg: "5%", md: "10%", sm: "15%", xs: "15%" },
          }}
        />
      </Box>
    </>
  );
};

export default Footer;
