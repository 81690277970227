import { Box } from "@mui/material";
import React from "react";
import espresso from "../../../../assets/espresso.png";
import crema from "../../../../assets/Our_Coffee/2nd/crema.png";
import blends from "../../../../assets/Our_Coffee/2nd/blends.png";
import { useStyles } from "./Style";
import crema_blend from "../../../../assets/Our_Coffee/2nd/crema_blend_text.png";
import brooklyn_blend from "../../../../assets/Our_Coffee/2nd/medium_blends_text.png";
import CoffeeVariations from "../../../../components/Shared/CoffeeVariation/CoffeeVariations";
//import bean from "../../../../assets/Our_Coffee/2nd/bean.png";
//import outline from "../../../../assets/outline.png";
//import cup from "../../../../assets/empty_cup.png";

const Section3RD = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        component={"img"}
        src={espresso}
        className={classes.espresso_title}
      />

      <CoffeeVariations
        link1st="https://shop.elevacoffee.com/collections/espresso-blends/products/crema?variant=48051011518657"
        p1st={crema}
        t1st={crema_blend}
        btn1st="Discover Crema"
        p2nd={blends}
        t2nd={brooklyn_blend}
        link2nd="https://shop.elevacoffee.com/collections/espresso-blends/products/crema?variant=48051011518657"
        btn2nd="Discover Brooklyn"
      />

      {/*<Box component={"img"} src={cup} className={classes.cup} loading="lazy" />
      <Box component={"img"} src={outline} className={classes.outline} />*/}

      {/*<Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ marginY: "7%" }}
        flexWrap={"wrap"}
      >
        <Box
          component={"img"}
          src={crema_blend}
          className={classes.crema_blend}
        />

        <Box component={"img"} src={bean} className={classes.bean} />
        <Box
          component={"img"}
          src={brooklyn_blend}
          className={classes.brooklyn_blend}
        />
      </Box>*/}
    </>
  );
};

export default Section3RD;
