import { TextField } from "@mui/material";
import React, { useState } from "react";

const Input = (props, ...rest) => {
  const {
    label,
    placeholder,
    type,
    name,
    onChange,
    onBlur,
    value,
    helpertext,
  } = props;

  return (
    <>
      {" "}
      <TextField
        {...rest}
        type={type}
        helperText={helpertext}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value || ""}
        fullWidth
        placeholder={placeholder}
        label={label}
        multiline
        sx={{
          "& .MuiOutlinedInput-root": {
            color: "#fff",
            fontFamily: "Arial",

            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "grey",
              borderWidth: "1px",
              color: "#fff",
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ededed",
                borderWidth: "2px",
              },
            },
            "& .MuiInputLabel-outlined": {
              color: "#2e2e2e",
              fontWeight: "bold",
              "&.Mui-focused": {
                color: "secondary.main",
                fontWeight: "bold",
              },
            },
          },
        }}
        InputLabelProps={{
          sx: {
            "&.Mui-focused": { color: "#fff" },
          },
        }}
      />
    </>
  );
};

export default Input;
