import React, { useState } from "react";
import Discover from "../Discoverbtn/Discover";
import { Box } from "@mui/material";
import { useStyles } from "../../../pages/OurCoffee/components/3rdSection/Style";
import { NavLink } from "react-router-dom";
import crema from "../../../assets/Our_Coffee/2nd/crema.png";
import crema_blend from "../../../assets/Our_Coffee/2nd/crema_blend_text.png";
import brooklyn_blend from "../../../assets/Our_Coffee/2nd/medium_blends_text.png";
import blends from "../../../assets/Our_Coffee/2nd/blends.png";

const CoffeeVariations = (props) => {
  const { link1st, p1st, t1st, btn1st, p2nd, t2nd, link2nd, btn2nd } = props;
  const [hover, setHover] = useState(false);

  const classes = useStyles();
  const SDevice = window.innerWidth < 900 ? true : false;
  const handleHover = (e) => {
    e.preventDefault();
    setHover(!hover);
  };
  const handleUnHover = (e) => {
    e.preventDefault();
    setHover(false);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      sx={{
        flexWrap: {
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "wrap",
          xs: "wrap",
        },
        justifyContent: {
          xl: "space-between",
          lg: "space-between",
          md: "space-between",
          sm: "center",
          xs: "center",
        },
      }}
      gap={2}
    >
      <Box>
        <NavLink
          to={link1st}
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
          style={{ textDecoration: "none" }}
        >
          <Box
            display={"flex"}
            sx={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
            }}
          >
            <Box>
              <Box
                component={"img"}
                src={t1st}
                className={classes.crema_blend}
              />
              <Box
                sx={{
                  display: {
                    xl: "block",
                    lg: "block",
                    md: "block",
                    sm: "none",
                    xs: "none",
                  },
                }}
              >
                <Discover link={link1st} title={btn1st} />{" "}
              </Box>
            </Box>

            <Box>
              <Box
                component={"img"}
                src={p1st}
                sx={{
                  width: { xl: "300px", lg: "250px", md: "200px", sm: "300px" },
                }}
              />
              <Box
                sx={{
                  display: {
                    xl: "none",
                    lg: "none",
                    md: "none",
                    sm: "block",
                    xs: "block",
                  },
                }}
              >
                <Discover link={link1st} title={btn1st} />{" "}
              </Box>
            </Box>
          </Box>
        </NavLink>
      </Box>

      {SDevice === true ? (
        <>
          <Box>
            <NavLink to={link2nd} style={{ textDecoration: "none" }}>
              <Box
                display={"flex"}
                sx={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "row",
                    sm: "column",
                    xs: "column",
                  },
                }}
              >
                <Box>
                  <Box
                    component={"img"}
                    src={t2nd}
                    className={classes.brooklyn_blend}
                  />
                  <Box
                    sx={{
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                  >
                    <Discover link={link2nd} title={btn2nd} />{" "}
                  </Box>
                </Box>
                <Box justifyContent={"center"}>
                  <Box
                    component={"img"}
                    src={p2nd}
                    sx={{
                      width: {
                        xl: "300px",
                        lg: "200px",
                        md: "250px",
                        sm: "300px",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: {
                        xl: "none",
                        lg: "none",
                        md: "none",
                        sm: "block",
                        xs: "block",
                      },
                    }}
                  >
                    <Discover link={link2nd} title={btn2nd} />{" "}
                  </Box>
                </Box>
              </Box>
            </NavLink>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <NavLink to={link2nd} style={{ textDecoration: "none" }}>
              <Box
                display={"flex"}
                sx={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "row",
                    sm: "column",
                    xs: "column",
                  },
                }}
              >
                <Box>
                  <Box
                    component={"img"}
                    src={p2nd}
                    sx={{
                      width: {
                        xl: "300px",
                        lg: "250px",
                        md: "200px",
                        sm: "300px",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: {
                        xl: "none",
                        lg: "none",
                        md: "none",
                        sm: "block",
                        xs: "block",
                      },
                    }}
                  >
                    <Discover link={link2nd} title={btn2nd} />{" "}
                  </Box>
                </Box>
                <Box>
                  <Box
                    component={"img"}
                    src={t2nd}
                    className={classes.brooklyn_blend}
                  />
                  <Box
                    sx={{
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                  >
                    <Discover link={link2nd} title={btn2nd} />{" "}
                  </Box>
                </Box>
              </Box>
            </NavLink>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CoffeeVariations;
