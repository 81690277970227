import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import React from "react";
import Footer from "../components/Footer'/Footer";

const LAYOUT_WITH_NAV = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default LAYOUT_WITH_NAV;
