import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Button,
  FormLabel,
  StepLabel,
} from "@mui/material";

// Define the validation schema using Yup
const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

// Custom styles for Material-UI components
const useStyles = makeStyles((theme) => ({
  formField: {
    margin: theme.spacing(1),
    minWidth: 300,
    borderRadius: "10px",
    padding: "10px",
    border: "1px solid #fff",
    backgroundColor: "transparent",
    color: "#fff",
    "& ::placeholder": {
      color: "#fff",
      fontSize: 18.9,
    },
  },
  ErrorMessage: {
    color: "red",
    fontSize: "0.8rem",
    display: "block",
    textAlign: "left",
  },
  submitButton: {
    margin: theme.spacing(1),
  },
  focusedInput: {
    "& .Mui-focused": {
      borderColor: "green", // Change the border color when focused
    },
  },
  mazen: {
    borderColor: "yellow !important",
  },

  root: {
    "& .MuiOutlinedInput-root": {
      // - The Input-root, inside the TextField-root
      "& fieldset": {
        // - The <fieldset> inside the Input-root
        borderColor: "pink", // - Set the Input border
      },
      "&:hover fieldset": {
        borderColor: "yellow", // - Set the Input border when parent has :hover
      },
      "&.Mui-focused fieldset": {
        // - Set the Input border when parent is focused
        borderColor: "green",
      },
    },
  },
}));

const FormikForm = () => {
  const classes = useStyles();
  return (
    <>
      <Formik
        initialValues={{ name: "", email: "" }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
        }) => (
          <FormControl error={touched.email && Boolean(errors.email)}>
            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"center"}>
              <Box>
                <ErrorMessage
                  name="name"
                  component="div"
                  className={classes.ErrorMessage}
                />
              </Box>
              <Box>
                <ErrorMessage
                  name="email"
                  component="div"
                  className={classes.ErrorMessage}
                />
              </Box>
            </Box>
            <Box>
              <Button
                type="submit"
                disabled={isSubmitting}
                className={classes.submitButton}
              >
                Submit
              </Button>
              <Box>
                <label>hello</label>
                <StepLabel>5</StepLabel>
                <FormLabel>helper</FormLabel>
                <InputLabel htmlFor="name">Name</InputLabel>
                <Input
                  InputProps={{ classes: { mazen: classes.input } }}
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-describedby="name-helper-text"
                />
              </Box>
            </Box>

            <Box>
              <FormControl variant="filled">
                <InputLabel
                  sx={{ color: "red", onBlur: { color: "#fff" } }}
                  htmlFor="my-input"
                >
                  Email address
                </InputLabel>
                <Input
                  id="my-input"
                  aria-describedby="my-helper-text"
                  onBlur={(e) => e.target.blur()}
                  className={classes.focusedInput}
                />
                <FormHelperText id="my-helper-text">
                  <ErrorMessage
                    name="email"
                    component="div"
                    className={classes.ErrorMessage}
                  />{" "}
                </FormHelperText>
              </FormControl>
            </Box>
          </FormControl>
        )}
      </Formik>
    </>
  );
};

export default FormikForm;
