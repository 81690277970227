import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  // Define your styles here
  root: {
    color: "white",
    padding: "0px",
  },
  /*** Box_Title ***/
  //////////////////
  Box_Title: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "25%",
    paddingBottom: "10%",
    "@media (min-width:0px)": {
      paddingTop: "25%",
      paddingBottom: "10%",
    },
    "@media (min-width:600px)": {
      paddingTop: "10%",
      paddingBottom: "10%",
    },
    "@media (min-width:900px)": {
      paddingTop: "10%",
      paddingBottom: "10%",
    },
    "@media (min-width:1200px)": {
      paddingTop: "15%",
      paddingBottom: "10%",
    },
    "@media (min-width:1536px)": {
      paddingTop: "15%",
      paddingBottom: "10%",
    },
  },
  /*** Box_Title_title ***/
  /////////////////////////
  Box_Title_title: {
    width: "100%",
    paddingLeft: "20%",
    paddingRight: "20%",
    "@media (min-width:0px)": {
      width: "100%",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    "@media (min-width:600px)": {
      width: "90%",
      paddingLeft: "7%",
      paddingRight: "7%",
    },
    "@media (min-width:900px)": {
      width: "85%",
      paddingLeft: "7%",
      paddingRight: "7%",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      marginRight: "-25%",
    },
    "@media (min-width:1536px)": {
      width: "80%",
      marginRight: "-35%",
    },
  },

  /*** Box_Title_subtitle ***/
  ///////////////////////
  Box_Title_subtitle: {
    "@media (min-width:0px)": {
      paddingTop: "4%",
    },
    "@media (min-width:600px)": {
      marginLeft: "0%",
      paddingTop: "4%",
    },
    "@media (min-width:900px)": {
      marginLeft: "0%",
      paddingTop: "4%",
    },
    "@media (min-width:1200px)": {
      marginLeft: "30%",
      paddingTop: "5%",
    },
    "@media (min-width:1536px)": {
      marginLeft: "35%",
      paddingTop: "5%",
    },
  },
  /*** Box_Title_subtitle_text ***/
  ///////////////////////
  Box_Title_subtitle_text: {
    "@media (min-width:0px)": { fontSize: "25px" },
    "@media (min-width:600px)": { fontSize: "35px" },
    "@media (min-width:900px)": { fontSize: "50px" },
    "@media (min-width:1200px)": { fontSize: "50px" },
    "@media (min-width:1536px)": { fontSize: "50px" },
  },
  /*** Box_Title_cup ***/
  /////////////////////////
  Box_Title_cup: {
    width: "100%",
    paddingLeft: "20%",
    paddingRight: "20%",
    "@media (min-width:0px)": {
      width: "100%",
      marginLeft: "-20%",
      display: "none",
    },
    "@media (min-width:600px)": {
      width: "100%",
      paddingLeft: "7%",
      paddingRight: "7%",
      display: "none",
    },
    "@media (min-width:900px)": {
      width: "100%",
      paddingLeft: "7%",
      paddingRight: "7%",
      display: "none",
    },
    "@media (min-width:1200px)": {
      width: "55%",
      marginLeft: "10%",
      display: "flex",
    },
    "@media (min-width:1536px)": {
      width: "45%",
      marginLeft: "0%",
      display: "flex",
    },
  },

  Btn_container: {
    "@media (min-width:0px)": { width: "150px", height: "40px" },
    "@media (min-width:600px)": { width: "150px", height: "40px" },
    "@media (min-width:900px)": { width: "200px", height: "50px" },
    "@media (min-width:1200px)": { width: "200px", height: "50px" },
    "@media (min-width:1536px)": { width: "200px", height: "50px" },
  },
  Btn_container_Box_Text: {
    "@media (min-width:0px)": {},
    "@media (min-width:600px)": {},
    "@media (min-width:900px)": {},
    "@media (min-width:1200px)": {},
    "@media (min-width:1536px)": {},
  },
  Btn_container_Box_text_text: {
    "@media (min-width:0px)": { fontSize: "17px" },
    "@media (min-width:600px)": { fontSize: "20px" },
    "@media (min-width:900px)": { fontSize: "20px" },
    "@media (min-width:1200px)": { fontSize: "20px" },
    "@media (min-width:1536px)": { fontSize: "20px" },
  },

  espresso_title: {
    "@media (min-width:0px)": {
      width: "100%",
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingTop: "5%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
    "@media (min-width:900px)": {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      paddingLeft: "5%",
      paddingRight: "0%",
      paddingTop: "30%",
    },
  },
});
