import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./Theme";
import "./App.css";
import ScrollToTop from "./hooks/ScrollToTop";
import Routes from "./Routes";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const [theme, colorMode] = useMode();
  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
            <HelmetProvider>
              <ScrollToTop />
              <Routes />
            </HelmetProvider>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export default App;
