import { Box, Container } from "@mui/material";
import React from "react";
import artistic from "../../../../assets/Home/6th/Slider/Slide_2/Artistic_Blending.png";
import curve from "../../../../assets/Home/6th/Slider/Slide_2/curve.png";
import text_hand from "../../../../assets/Home/6th/Slider/Slide_2/artistic_text_hands_illistration.png";
import nicaragua_mount from "../../../../assets/Home/6th/Slider/Slide_2/nicaragua_mountain_image.png";
import mountain from "../../../../assets/Home/6th/Slider/Slide_2/mountains_image.png";
import mountain_gold from "../../../../assets/Home/6th/Slider/Slide_2/mountain_gold.png";

const Slide3Rd = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Box my={2}>
          <Box
            mt={0}
            display={"flex"}
            sx={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
            }}
            justifyContent={"space-around"}
          >
            <Box>
              <Box
                component={"img"}
                src={artistic}
                sx={{
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "80%",
                    sm: "80%",
                    xs: "80%",
                  },
                }}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  width: {
                    xl: "80%",
                    lg: "80%",
                    md: "60%",
                    sm: "60%",
                    xs: "50%",
                  },
                }}
                component={"img"}
                src={curve}
              />
            </Box>
          </Box>
          <Box
            mt={0}
            display={"flex"}
            justifyContent={"space-around"}
            sx={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
            }}
          >
            <Box>
              <Box
                component={"img"}
                src={text_hand}
                sx={{
                  width: {
                    xl: "65%",
                    lg: "65%",
                    md: "60%",
                    sm: "65%",
                    xs: "70%",
                  },
                  marginBottom: {
                    lg: "-15%",
                    md: "-15%",
                    sm: "-15%",
                    xs: "-20%",
                  },
                }}
              />
              <Box
                component={"img"}
                src={mountain}
                sx={{
                  width: {
                    xl: "",
                    lg: "0",
                    md: "70%",
                    sm: "",
                    xs: "",
                  },
                }}
              />
            </Box>
            <Box
              component={"img"}
              src={nicaragua_mount}
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "100%",
                  sm: "",
                  xs: "",
                },
                height: {
                  xl: "50%",
                  lg: "50%",
                  md: "100%",
                  sm: "",
                  xs: "",
                },
                paddingTop: 10,
              }}
            />
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Box
              component={"img"}
              src={mountain_gold}
              sx={{
                width: {
                  xl: "0%",
                  lg: "0",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
                marginTop: {
                  xl: "-20%",
                  lg: "-20%",
                  md: "-12%",
                  sm: "-20%",
                  xs: "-20%",
                },
              }}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Slide3Rd;
