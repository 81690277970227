import React from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import SharedBTN from "../SharedBTN/SharedBTN";

const PostCard = ({ post, setCurrentId }) => {
  const ShortContent =
    post.content.length > 145
      ? post.content.substr(0, 145) + "..."
      : post.content;
  const PostTitle =
    post.title.length > 30 ? post.title.substr(0, 30) + "..." : post.title;

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          justifyContent: {
            xl: "flex-start",
            lg: "flex-start",
            md: "center",
            sm: "center",
            xs: "center",
          },
          padding: "20px",
        }}
      >
        <Box>
          <Card
            sx={{
              maxWidth: 400,
              width: { xs: "90vw" },
              height: "100%",
              border: "solid 1px gold",
              m: "10px",
            }}
          >
            <NavLink
              to={`/eleva-latest-news/${post._id}`}
              style={{ textDecoration: "none" }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  width="500px"
                  height={"250px"}
                  sx={{ objectFit: "cover" }}
                  image={
                    post.selectedFile ||
                    "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
                  }
                  alt={""}
                />{" "}
              </CardActionArea>
              <CardContent sx={{ textAlign: "left" }}>
                <Typography
                  gutterBottom
                  variant="h3"
                  component="div"
                  color={"goldenrod"}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      color: "#fff",
                    },
                  }}
                >
                  {PostTitle}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color={"text.secondary"}
                >
                  <Chip label={post.author} /> <Chip label={post.category} />
                </Typography>
                <Box paddingTop={1}>
                  <Typography variant="h5" color="#fff">
                    {ShortContent}
                  </Typography>
                </Box>
              </CardContent>

              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "15px",
                }}
              >
                <SharedBTN
                  bgBtnHover=""
                  bgBtn="goldenrod"
                  title="Discover..."
                  width="40%"
                  height="100%"
                />
              </CardActions>
            </NavLink>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default PostCard;
