import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";

const Discover = (props) => {
  const { link, title } = props;
  const [hover, setHover] = useState(false);

  const handleHover = (isHovering) => {
    setHover(isHovering);
  };
  return (
    <Box>
      <NavLink
        style={{
          textDecoration: "none",
          color: "#fff",
        }}
        to={link}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        <Box display={"flex"} justifyContent={"center"} gap={1}>
          <Box>
            <Typography
              sx={{
                fontSize: hover
                  ? {
                      xl: "1.7rem",
                      lg: "1.7rem",
                      md: "1.7rem",
                      sm: "1.3rem",
                      xs: "1.3rem",
                    }
                  : {
                      xl: "1.6rem",
                      lg: "1.6rem",
                      md: "1.6rem",
                      sm: "1.4rem",
                      xs: "1.4rem",
                    },
                color: hover ? "" : "goldenrod",
              }}
              gutterBottom
            >
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              paddingTop: {
                xl: "6px",
                lg: "6px",
                md: "10px",
                sm: "",
                xs: "4px",
              },
            }}
          >
            <ArrowCircleRightRoundedIcon
              sx={{
                fontSize: {
                  xl: "30px",
                  lg: "30px",
                  md: "25px",
                  sm: "20px",
                  xs: "25px",
                },
                color: hover ? "" : "goldenrod",
              }}
            />{" "}
          </Box>
        </Box>
      </NavLink>
    </Box>
  );
};

export default Discover;
