import React from "react";
import { Box } from "@mui/material";
import Menu from "./Menu";
import Logo from "./Logo";
import RHeader from "./Responsive/RHeader";
//import "./style.css";
import { useStyles } from "./Style";

const Header = () => {
  const classes = useStyles();
  return (
    <>
      <Box>
        <Box className={classes.RHeader}>
          <RHeader />
        </Box>
        <Box
          component={"header"}
          sx={{
            display: {
              xl: "flex",
              lg: "flex",
              md: "none",
              sm: "none",
              xs: "none",
            },
            position: "fixed",
            justifyContent: "space-around",
            zIndex: 5,
          }}
        >
          <Box display="flex" justifyContent={"stretch"} p={1}>
            {/* LOGO  */}
            <Box
              sx={{ marginLeft: "10%", marginRight: { xl: "50%", lg: "20%" } }}
            >
              <Logo />
            </Box>
            {/* SEARCH BAR*/}
            {/*<SearchBar />*/}
            {/* MENU BAR  \*/}
            <Box sx={{}}>
              <Menu />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
