import React from "react";
import { Box, Container } from "@mui/material";
import PostCard from "../BlogSection/PostCard";
import PostCardSkeleton from "../../Skelton/PostCardSkelton";
import { useSelector } from "react-redux";

const BlogSection = ({ setCurrentId }) => {
  const posts = useSelector((state) => state.posts);

  return (
    <>
      {posts.length > 0 ? (
        <>
          <Container maxWidth="xl">
            <Box
              display={"flex"}
              sx={{
                justifyContent: {
                  xl: "flex-start",
                  lg: "flex-start",
                  md: "center",
                  sm: "center",
                  xs: "center",
                },
                alignContent: "left",
                alignItems: "left",
              }}
              flexWrap={"wrap"}
            >
              {posts.map((post) => (
                <Box key={post._id}>
                  <PostCard post={post} setCurrentId={setCurrentId} />
                </Box>
              ))}
            </Box>
          </Container>
        </>
      ) : (
        <>
          <PostCardSkeleton />
        </>
      )}
    </>
  );
};

export default BlogSection;
