import { Box } from "@mui/material";
import { useStyles } from "./Style";

import React from "react";
import cup from "../../../../assets/empty_cup.png";
import cold_coffee from "../../../../assets/Our_Coffee/3rd/cold_coffee_cup.png";
import dual_curve from "../../../../assets/Our_Coffee/3rd/dual_curve.png";
import special_blends from "../../../../assets/Our_Coffee/3rd/special_blends.png";
import curve from "../../../../assets/Our_Coffee/3rd/curve.png";
import decaf from "../../../../assets/decaf_text.png";
import dark_roast from "../../../../assets/Our_Coffee/3rd/dark_roast.png";
import mountain_gold from "../../../../assets/Our_Coffee/3rd/mountain_gold.png";
import { NavLink } from "react-router-dom";
import newyork_blend from "../../../../assets/newyork_text.png";
import colombia from "../../../../assets/Our_Coffee/3rd/colombia.png";
import CoffeeVariations from "../../../../components/Shared/CoffeeVariation/CoffeeVariations";

const Section4TH = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        sx={{ marginY: "2%" }}
        flexWrap={"wrap"}
      >
        <Box className={classes.cold_box}>
          <Box
            component={"img"}
            src={cold_coffee}
            className={classes.cold_img}
          />
        </Box>
        <Box className={classes.dual_box}>
          <Box
            component={"img"}
            src={dual_curve}
            className={classes.dual_img}
          />
        </Box>
        <Box className={classes.special_box}>
          <Box
            component={"img"}
            src={special_blends}
            className={classes.special_img}
          />
        </Box>
        <Box className={classes.curve_box}>
          <Box component={"img"} src={curve} className={classes.curve_img} />
        </Box>
      </Box>
      <CoffeeVariations
        link1st="https://shop.elevacoffee.com/collections/special-blends/products/decaf?variant=40907527939"
        p1st={colombia}
        t1st={decaf}
        btn1st="Discover Decaf"
        p2nd={dark_roast}
        t2nd={newyork_blend}
        link2nd="https://shop.elevacoffee.com/collections/special-blends/products/dark-roast?variant=8107307106400"
        btn2nd="Discover Dark Roast"
      />{" "}
      <Box component={"img"} src={cup} className={classes.cup_img} />
      <Box
        zIndex={1}
        component={"img"}
        src={mountain_gold}
        className={classes.mountain_img}
      />
    </>
  );
};

export default Section4TH;
