import { Box, Container } from "@mui/material";
import React from "react";
import bean from "../../../../assets/Home/6th/Slider/Slide_1/bean.png";
import everyCup from "../../../../assets/Home/6th/Slider/Slide_1/craft_coffee_journey.png";
import Source from "../../../../assets/Home/6th/Slider/Slide_1/eleva_coffee_sourcing.png";
import nicaragua_map from "../../../../assets/Home/6th/Slider/Slide_1/nicaragua_word_map.png";
import nicaragua_text from "../../../../assets/Home/6th/Slider/Slide_1/slide_1_text.png";
import { useStyle } from "./style";

const Slide2Nd = () => {
  const classes = useStyle();
  return (
    <>
      <Container
        sx={{ maxWidth: { xl: "xl", lg: "xl", md: "xl", sm: "sm", xs: "xs" } }}
      >
        <Box
          display={"flex"}
          sx={{
            paddingY: { xl: 1, lg: 5, md: 10, sm: 5, xs: 5 },
            paddingX: { xl: 2, lg: 10, md: 2, sm: 0, xs: 4 },
            flexWrap: {
              xl: "nowrap",
              lg: "nowrap",
              md: "nowrap",
              sm: "wrap",
              xs: "wrap",
            },
          }}
        >
          <Box>
            <Box
              component={"img"}
              src={Source}
              sx={{
                width: {
                  xl: "100%",
                  lg: "120%",
                  md: "125%",
                  sm: "80%",
                  xs: "90%",
                },
              }}
            />
          </Box>
          <Box>
            <Box
              component={"img"}
              src={everyCup}
              sx={{
                width: {
                  xl: "450px",
                  lg: "75%",
                  md: "75%",
                  sm: "",
                  xs: "",
                },
                marginLeft: {
                  xl: "25%",
                  lg: "45%",
                  md: "30%",
                  sm: "0%",
                  xs: "30%",
                },
              }}
            />
          </Box>
          <Box>
            <Box
              component={"img"}
              src={bean}
              sx={{
                paddingTop: { xl: "50px", lg: "20px" },
                width: {
                  xl: "5vw",
                  lg: "7vw",
                  md: "10vw",
                  sm: "",
                  xs: "",
                },
              }}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          px={6}
          sx={{
            width: "100%",
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
          }}
        >
          <Box>
            <Box
              component={"img"}
              src={nicaragua_map}
              sx={{
                width: {
                  xl: "70%",
                  lg: "70%",
                  md: "85%",
                  sm: "70%",
                  xs: "70%",
                },
                marginTop: {
                  xl: "-15%",
                  lg: "-25%",
                  md: "-25%",
                  sm: "-20%",
                  xs: "-35%",
                },
              }}
            />
          </Box>
          <Box>
            <Box
              component={"img"}
              src={nicaragua_text}
              sx={{
                width: {
                  xl: "100%",
                  lg: "95%",
                  md: "100%",
                  sm: "100%",
                  xs: "300px",
                },
              }}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Slide2Nd;
