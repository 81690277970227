import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  // Define your styles here

  hero_bg: {
    width: "100%",
    marginLeft: "-2%",
    "@media (min-width:0px)": {
      width: "100%",
      marginTop: "-0px",
      height: "20vh",
      display: "none",
    },
    "@media (min-width:600px)": {
      width: "100%",
      marginTop: "-20px",
      height: "20vh",
      display: "none",
    },
    "@media (min-width:900px)": {
      width: "100%",
      marginTop: "-10%",
      display: "flex",
      height: "100vh",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      height: "100vh",
      marginTop: "-10px",
      display: "flex",
    },
    "@media (max-width:1440px)": {
      width: "100%",
      height: "100vh",
      marginTop: "-10px",
      display: "flex",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      height: "100vh",
      marginTop: "-10px",
      display: "flex",
    },
  },

  hero_container: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "100%",
      marginTop: "0%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media (min-width:600px)": {
      width: "100%",
      marginTop: "0%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    "@media (min-width:900px)": {
      width: "100%",
      marginTop: "0%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      marginTop: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media (max-width:1440px)": {
      width: "100%",
      marginTop: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      marginTop: "0%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  hero_container_logo: {
    width: "100%",
    "@media (min-width:0px)": {
      paddingLeft: "0%",
      paddingRight: "0%",
      width: "50%",
    },
    "@media (min-width:600px)": {
      paddingLeft: "0%",
      paddingRight: "0%",
      width: "45%",
    },
    "@media (min-width:900px)": {
      paddingLeft: "0%",
      paddingRight: "0%",
      width: "50%",
    },
    "@media (min-width:1200px)": {
      paddingLeft: "0%",
      paddingRight: "0%",
      paddingTop: "5%",
      width: "45%",
    },
    "@media (min-width:1536px)": {
      paddingLeft: "0%",
      paddingRight: "0%",
      width: "45%",
    },
  },

  slogen: {
    width: "100%",

    "@media (min-width:0px)": {
      width: "80%",
      marginTop: "-20%",
    },
    "@media (min-width:600px)": {
      width: "50%",
      marginTop: "-8%",
    },
    "@media (min-width:900px)": {
      width: "50%",
      marginTop: "-8%",
    },
    "@media (min-width:1200px)": {
      width: "50%",
      marginTop: "-8%",
    },
    "@media (min-width:1536px)": {
      width: "50%",
      marginTop: "-8%",
    },
  },

  Container_btn: {
    "@media (min-width:0px)": { paddingTop: "8%" },
    "@media (min-width:600px)": { marginTop: "0%" },
    "@media (min-width:900px)": { marginTop: "0%" },
    "@media (min-width:1200px)": { marginTop: "0%" },
    "@media (max-width:1440px)": { marginTop: "-5%" },
    "@media (min-width:1536px)": { marginTop: "-5%" },
  },
  hero_btn: {
    textTransform: "lowercase",
    "@media (min-width:0px)": {
      height: "45px",
      alignItems: "center",
    },
    "@media (min-width:600px)": {
      height: "45px",
    },
    "@media (min-width:900px)": {
      height: "45px",
      alignItems: "center",
    },
    "@media (min-width:1200px)": {
      height: "45px",
      alignItems: "center",
    },
    "@media (min-width:1536px)": {
      height: "65px",
      width: "250px",
      alignItems: "center",
    },
  },
});
