import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { NavLink } from "react-router-dom";

import PlaceIcon from "@mui/icons-material/Place";

const ProductCard = ({
  Category,
  Country,
  RoastType,
  Price,
  ShopifyLink,
  ProductFront,
  ProductBack,
  CoffeeTaste,
}) => {
  const [flip, setFlip] = useState(false);

  const handleEnter = (e) => {
    setFlip(true);
  };
  const handleLeave = (e) => {
    setFlip(!flip);
  };
  return (
    <>
      <Box
        onMouseOver={() => {
          handleEnter();
        }}
        onMouseLeave={handleLeave}
      >
        {flip ? (
          <>
            <Box marginTop={"12%"}>
              <Box component={"img"} src={ProductBack} alt="" width={"100%"} />
            </Box>
          </>
        ) : (
          <>
            <Box component={"img"} src={ProductFront} alt="" width={"95%"} />
          </>
        )}
        <Box
          mx={2}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Box>
            <Typography sx={{ fontSize: "2rem", textAlign: "left" }}>
              {Category}
            </Typography>
          </Box>
          <Box display={"none"}>
            <Typography sx={{ fontSize: "2rem", textAlign: "left" }}>
              {Price} $
            </Typography>
          </Box>
        </Box>
        <Box
          mx={2}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                textAlign: "left",
              }}
            >
              <PlaceIcon sx={{ color: "goldenrod", display: "none" }} />{" "}
              {Country}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h3"
              sx={{
                textAlign: "left",
              }}
            >
              {RoastType}
            </Typography>
          </Box>
        </Box>
        <Box
          mx={2}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                textAlign: "left",
              }}
            >
              {CoffeeTaste}
            </Typography>
          </Box>
          <Box marginTop={"-35px"}>
            <NavLink to={ShopifyLink}>
              <Button variant="contained" sx={{ backgroundColor: "goldenrod" }}>
                {" "}
                <Typography
                  variant="h5"
                  sx={{ color: "#fff", textTransform: "capitalize" }}
                >
                  Buy Now
                </Typography>
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProductCard;
