import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";

const SharedBTN = (props) => {
  const { title, bgBtn, bgBtnHover, type, onClick, width, height } = props;
  const [hover, setHover] = useState(false);

  const handleHover = () => {
    setHover(true);
  };

  const handleNotHover = () => {
    setHover(false);
  };

  return (
    <Button
      onClick={onClick}
      type={type}
      sx={{
        borderRadius: "10px",
        width: `${width}`,
        height: `${height}`,
        textTransform: "capitalize",
        backgroundColor: `${bgBtn}`,
        "&:hover": {
          backgroundColor: hover ? `${bgBtnHover}` : `${bgBtn}`,
          border: hover ? "solid 1px goldenrod " : "",
        },
      }}
      onMouseOver={handleHover}
      onMouseLeave={handleNotHover}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "16px",
            textTransform: "capitalize",
            color: hover ? "goldenrod" : "#ffff",
            textAlign: "left",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Button>
  );
};

export default SharedBTN;
