export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH_POST = "FETCH_POST";
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const FETCH_WHOLESALE = "FETCH_WHOLESALE";
export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";
export const LIKE = "LIKE";
