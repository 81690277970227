import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  Container,
  CssBaseline,
  Paper,
  Typography,
  Alert,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createOrder, updateOrder } from "../../../../actions/wholesale";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const WholeSaleForm = ({ currentId, setCurrentId }) => {
  const [success, setSuccess] = useState(false);
  const [faild, setFaild] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    businessname: "",
    phonenumber: "",
    email: "",
    address: "",
    state: "",
    city: "",
    zipcode: "",
    message: "",
  });
  const order = useSelector((state) =>
    currentId ? state.orders.find((message) => message._id === currentId) : null
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (order) setFormData(order);
  }, [order]);

  const clear = () => {
    setCurrentId(0);
    setFormData({
      name: "",
      businessname: "",
      phonenumber: "",
      email: "",
      address: "",
      state: "",
      city: "",
      zipcode: "",
      message: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(true);

    if (currentId === 0) {
      dispatch(createOrder(formData));
      setTimeout(() => {
        navigate("/");
      }, 2000);

      toast.success("Order Created Successfully");
      clear();
    } else {
      dispatch(updateOrder(currentId, formData));
      setFaild(true);
      clear();
    }
  };

  return (
    <>
      <CssBaseline />
      <Box>
        <Typography
          sx={{
            fontFamily: "Gallient",
            color: "#dec87c",
            fontSize: {
              xl: "1.8rem",
              lg: "1.8rem",
              md: "1.8rem",
              sm: "1.8rem",
              xs: "1.8rem",
            },
            textAlign: "center",
          }}
        >
          Join us now{" "}
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          {" "}
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <TextField
              required
              fullWidth
              id="First & LastName"
              placeholder="First & Last Name"
              name="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <TextField
              require
              fullWidth
              id="Business Name"
              label="Business Name"
              name="businessname"
              autoComplete="Business Name"
              value={formData.businessname}
              onChange={(e) =>
                setFormData({ ...formData, businessname: e.target.value })
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <TextField
              r
              fullWidth
              id="phone Number"
              label="Phone Number"
              name="phonenumber"
              autoComplete="phone Number"
              value={formData.phonenumber}
              onChange={(e) =>
                setFormData({ ...formData, phonenumber: e.target.value })
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <TextField
              required
              type="email"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              id="address"
              label="Address"
              name="address"
              value={formData.address}
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              autoComplete="Address"
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              required
              fullWidth
              name="state"
              label="State"
              type="State"
              id="State"
              value={formData.state}
              onChange={(e) =>
                setFormData({ ...formData, state: e.target.value })
              }
              autoComplete="State"
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              required
              fullWidth
              name="city"
              label="City"
              type="City"
              id="City"
              InputLabelProps={{ shrink: false }}
              SelectProps={{
                displayEmpty: true,
              }}
              value={formData.city}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              autoComplete="City"
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              required
              fullWidth
              name="zipcode"
              label="ZIP Code"
              id="ZIP Code"
              value={formData.zipcode}
              onChange={(e) =>
                setFormData({ ...formData, zipcode: e.target.value })
              }
              autoComplete="ZIP Code"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              multiline
              rows={3}
              fullWidth
              name="message"
              label="Message"
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              type="Message"
              id="Message"
              autoComplete="Message"
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              m: 3,
              borderRadius: "20px",
              height: "50px",
              backgroundColor: "darkgoldenrod",
              ":hover": {
                backgroundColor: "goldenrod",
              },
            }}
          >
            Submit
          </Button>
        </Grid>
      </Box>{" "}
      <Box>
        {success && (
          <Alert sx={{ borderRadius: "20px" }}>
            {" "}
            Form submitted successfully!
          </Alert>
        )}

        {faild && (
          <Alert sx={{ borderRadius: "20px" }}> Form faild to submit!</Alert>
        )}
      </Box>
    </>
  );
};

export default WholeSaleForm;
